<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-row">
        At {{ domainConfig['IAmURL'] }}, we are committed to protecting your privacy. This policy
        outlines how we collect, use, and safeguard your information.
      </div>

      <div class="common-content-row">
        <ol>
          <li>
            <strong>Information Collection</strong>: We collect minimal personal information, such
            as your email address, when you contact us or subscribe to our newsletter.
          </li>
          <li>
            <strong>Use of Information</strong>: Your information is used to respond to inquiries,
            send newsletters, and improve our services.
          </li>
          <li>
            <strong>Data Security</strong>: We implement industry-standard security measures to
            protect your data from unauthorized access, alteration, or disclosure.
          </li>
          <li>
            <strong>Third-Party Sharing</strong>: We do not sell or share your personal information
            with third parties, except as required by law or to protect our rights.
          </li>
          <li>
            <strong>Cookies</strong>: Our site may use cookies to enhance user experience by
            remembering your preferences. You can choose to disable cookies in your browser
            settings, but this may affect site functionality.
          </li>
          <li>
            <strong>Children's Privacy</strong>: Our site is not intended for children under 13. We
            do not knowingly collect personal information from children under 13.
          </li>
          <li>
            <strong>Changes to Privacy Policy</strong>: We may update this policy periodically.
            Changes will be posted on this page with an updated effective date.
          </li>
        </ol>
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Private Policy | ${this.domainConfig?.['meta']?.title || 'Default Title'}`,
      meta: [
        {
          name: 'description',
          content: `${this.domainConfig?.['meta']?.des || 'Default des'}`,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>